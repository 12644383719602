import React from "react";

const SellStartup3Steps = () => {
  return (
    <div className="max-w-6xl mx-auto py-14 justify-center text-center">
        <h3 className="text-4xl text-bblue pb-10">
          ¡Cómo vender tu proyecto en tres pasos!
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <Card
            nmb="01."
            title="Registrate"
            titleColor="#5769b1"
            description="Date de alta en nuestra plataforma para empezar el proceso."
          />
          <Card
            nmb="02."
            title="Crea tu proyecto"
            titleColor="#ee2163"
            description="Comparte la información más importante de tu proyecto para aparecer en el listado."
          />
          <Card
            nmb="03."
            title="Recibe propuestas"
            titleColor="#170f2e"
            description="Responde a los mensajes que recibirás de gente interesada en tu proyecto."
          />
        </div>
      </div>
  );
};

export default SellStartup3Steps;

const Card = ({title, titleColor, description, nmb}) => {
  return (
    <div className="bg-white border-2 border-dgrey rounded-2xl px-5 py-4">
      <h1 className="text-4xl md:text-5xl opacity-20 font-bold text-dpurple nexaTextBold pb-2 pt-2">
        {nmb}
      </h1>
      <h1
        className="text-2xl md:text-3xl font-bold text-dpurple nexaTextBold pb-4"
        style={{color: titleColor}}
      >
        {title}
      </h1>
      <p
        className="text-lg md:text-xl text-dpurple font-normal NexaTextDemoLight pb-2"
        style={{wordSpacing: "8px"}}
      >
        {description}
      </p>
    </div>
  );
};
