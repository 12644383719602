import React from "react";
import {Layout} from "@startuponsale/gatsby-shared-ui";
import Header from "./Header";
import Struggling from "./Struggling";
import Testimonial from "./Testimonial";
import CallToAction from "../../components/CallToAction";
import SellStartup3Steps from "../../components/SellStartup3Steps";
import Services from "../../components/Services"

const Index = () => {
  return (
    <div>
      <Layout>
        <Header />
        <Services />
        <Struggling />
        <Testimonial />
        <SellStartup3Steps />
        <CallToAction title="Únete a la primera la comunidad hispana de compra/venta de negocios." 
                      action="Darme de alta" 
                      link={process.env.APP_URL + "/registro/nuevo"}/>
      </Layout>
    </div>
  );
};

export default Index;
