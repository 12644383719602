import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Header = () => {
  return (
    <div className="w-full bg-lblue">
      <div className="max-w-6xl mx-auto py-10">
        <div className="">
          <div className="w-full text-center"> {/* HEADING */}
            <h1 className="text-6xl w-3/4 mx-auto pb-16">El marketplace para comprar y vender negocios digitales</h1>
            <div className="flex mb-10 md:mb-0 mx-auto w-fit">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="blue-button w-auto text-xl mx-5">Ver proyectos</a>
                <a href={process.env.APP_URL + "/registro/nuevo"} className="pink-button w-auto text-xl mx-5">Vender mi proyecto</a>
            </div>
          </div> 
          <div className="flex text-center items-center">
            <div className="w-2/5 mx-auto md:max-w-xl">
              <p className=" text-lg text-dpurple">
                Únete a la comunidad dónde publicar y vender tus proyectos. Ponte en contacto con compradores interesados rápidamente y de manera gratuita.
              </p>
            </div>
            <div className="md:block hidden">
              <StaticImage src="../../images/landing.png" alt="" layout="fixed" height={500} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
