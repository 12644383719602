import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import {GoPrimitiveDot} from "react-icons/go";

const Struggling = () => {
  return (
      <div className="max-w-6xl mx-auto py-10">
        <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-2 mb-20">
            <div className="rounded-lg shadow-xl">
              <StaticImage
                src="../../images/Group 17 (copy).png"
                alt="stuggle"
                className="rounded-2xl md:max-w-md object-cover stuugleImg" 
                placeholder="blurred"
                layout="fixed" height={500} width={500}
              />
            </div>
          <div className="md:max-w-2xl">
            <div className="pl-20">
              <div className="pb-16 w-fit text-dpurple">
                <h2 className="text-4xl text-bblue">
                  Para Emprendedores
                </h2>
                <p className="text-lg justify">Sabemos que arrancar un negocio no es fácil, pero monetizarlo no tiene porque ser difícil. Lista tu proyecto en nuestro directorio y ten acceso a fácil a compradores.</p>
              </div>
              <Text iconColor="#ee2163"><p className="nexabold inline">Gratis</p>, listar tu proyecto no tiene coste.</Text>
              <Text iconColor="#ee2163"><p className="nexabold inline">Privado</p>, tus datos no se comparten si no quieres.</Text>
              <Text iconColor="#ee2163"><p className="nexabold inline">Rápido</p>, recibe ofertas en menos de 30 días.</Text>
              <Text iconColor="#ee2163"><p className="nexabold inline">Acompañado</p>, Contacta con asesores que te ayudarán en el proceso.</Text>
              <div className="mx-auto justify-center flex w-6/12">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="pink-button w-auto">
                  Tengo un proyecto 
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-2 mb-20">
          <div className=" md:max-w-2xl">
            <div className="pl-20 md:pr-20 md:pl-0">
            <div className="pb-16 w-fit text-dpurple">
                <h2 className="text-4xl text-bblue">
                  Para Compradores
                </h2>
                <p className="text-lg justify">Encuentra el proyecto que necesitas de manera ágil. Reduce los riesgos de la adquisición verificando la información previamente.  </p>
              </div>
              
              <Text iconColor="#5769b1">Añadimos proyectos al listado constantemente.</Text>
              <Text iconColor="#5769b1">Selecciona entre los proyectos previamente filtrados por nosotros.</Text>
              <Text iconColor="#5769b1">Reduce el tiempo de Due Dilligence.</Text>
              <Text iconColor="#5769b1">Te asesoramos durante el proceso de compra.</Text>
              <div className="mx-auto justify-center flex w-6/12">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="blue-button w-auto">
                  Busco proyectos 
                </a>
              </div>
            </div>
          </div>

          <div className="rounded-lg shadow-xl">
            <StaticImage
              src="../../images/Group 18 (copy).png"
              alt="project"
              className="rounded-2xl md:max-w-md object-cover shadow-xl projectimg"
              placeholder="blurred"
              layout="fixed" height={500} width={500}
            />
          </div>
        </div>
      </div>
  );
};

export default Struggling;

const Text = ({children, iconColor}) => {
  return (
    <div className="flex pb-3">
      <span>
        <GoPrimitiveDot className="w-7 h-7" style={{color: iconColor}} />
      </span>
      <span className="text-lg nexalight pl-1.5">
        {children}
      </span>
    </div>
  );
};
