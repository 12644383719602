import React, {useState, useEffect, useCallback, useRef} from "react";
import {Link} from "gatsby";
import leftico from "../../images/quleft.png";
import rightico from "../../images/quright.png";
import { StaticImage } from "gatsby-plugin-image";

import {BsArrowRight, BsArrowLeft} from "react-icons/bs";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Virtual, Navigation, Pagination} from "swiper/core";

SwiperCore.use([Virtual]);
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);


const Testimonial = () => {
  const swiperRef = useRef(null);

  const [currentIndex, updateCurrentIndex] = useState(0);
  const params = {
    initialSlide: 2,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    spaceBetween: 30,
    loop: true,
    autoplay: true
  };

  // Manipulate swiper from outside
  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      updateIndex();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const updateIndex = useCallback(
    () => updateCurrentIndex(swiperRef.current.swiper.realIndex),
    []
  );
  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    const swiperInstance = swiperRef.current.swiper;

    if (swiperInstance) {
      swiperInstance.on("slideChange", updateIndex);
    }

    return () => {
      if (swiperInstance) {
        swiperInstance.off("slideChange", updateIndex);
      }
    };
  }, [updateIndex, swiperRef]);

  const slidePerView = 1;

  return (
    <div className="max-w-6xl mx-auto py-10">
        <div className="flex flex-col md:flex-row justify-center md:justify-between gap-4">
          <div className="md:max-w-lg flex self-center px-4">
            <div>
              <span className=" text-white text-lg rounded-sm py-0.5 px-4 font-normal nexabold bg-dblue">
                Testimonios
              </span>
              <h3 className="text-4xl text-bblue">
                ¡Vender tu proyecto ahora es fácil!
              </h3>
              <p className="text-left text-lg text-dpurple nexalight pb-7">
                Hemos digitalizado el proceso de venta: preparar la documentación, practicar el pitch, buscar posibles compradores, la due dilligence... ahora será rápido.
              </p>
              <div className="flex justify-start mb-12">
                <a href={process.env.APP_URL + "/registro/nuevo"} className="pink-button w-auto text-xl">Vender mi proyecto</a>
              </div>
              <div className="flex flex-row justify-end md:justify-start">
                <a className="mr-5" onClick={goPrev}>
                  <BsArrowLeft className="text-3xl cursor-pointer transition-all hover:shadow-xl text-white bg-dpurple w-10 h-10 p-2 rounded-full" />
                </a>
                <a className="" onClick={goNext}>
                  <BsArrowRight className="text-3xl cursor-pointer transition-all hover:shadow-xl text-white bg-dpurple w-10 h-10 p-2 rounded-full" />
                </a>
              </div>
            </div>
          </div>
          <div className="bg-dblue px-5 md:px-16 py-10 md:py-14 max-w-md">
            <Swiper
              slidesPerView={slidePerView}
              navigation
              {...params}
              ref={swiperRef}
            >
              <SwiperSlide>
                <div className="flex justify-start mb-6">
                  <img src={leftico} alt="" className="w-10 md:w-14" />
                </div>
                <div>
                  <h1 className="text-left text-lg md:text-xl text-white font-bold nexaTextBold pb-1">
                    Genera empatía y engánchalos
                  </h1>
                  <p className="text-left text-sm md:text-base text-white font-normal NexaTextDemoLight pb-2">
                    Debes ser natural y hablar desde una experiencia personal,
                    desde el corazón.
                  </p>
                  <div className="flex justify-end">
                    <img src={rightico} alt="" className="w-10 md:w-14" />
                  </div>
                  <div className="flex">
                    <div>
                      <StaticImage
                        src="../../images/AngelM.jpg"
                        alt=""
                        className="w-14 h-14 md:w-20 md:h-20 rounded-full"
                      />
                    </div>
                    <div className="flex self-center">
                      <div className="pl-4">
                        <h1 className="text-left text-base md:text-lg text-white font-semibold nexaTextBold ">
                          Jaime Muñoz
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex justify-start mb-6">
                  <img src={leftico} alt="" className="w-10 md:w-14" />
                </div>
                <div>
                  <h1 className="text-left text-lg md:text-xl text-white font-bold nexaTextBold pb-1">
                    Enlista los datos esenciales
                  </h1>
                  <p className="text-left text-sm md:text-base text-white font-normal NexaTextDemoLight pb-2">
                    Evita la incómoda situación de tener que hacer cara de “no
                    sé, no tengo ese dato a la mano” y considera todo lo que
                    podrían preguntarte. Ten al alcance los obvios y los no tan
                    obvios. Nunca sabes cuándo te los pueden pedir.
                  </p>
                  <div className="flex justify-end">
                    <img src={rightico} alt="" className="w-10 md:w-14" />
                  </div>
                  <div className="flex">
                    <div>
                      <StaticImage
                        src="../../images/MarcosJ.jpg"
                        alt=""
                        className="w-14 h-14 md:w-20 md:h-20 rounded-full"
                      />
                    </div>
                    <div className="flex self-center">
                      <div className="pl-4">
                        <h1 className="text-left text-base md:text-lg text-white font-semibold nexaTextBold ">
                          Marcos Jiménez
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
  );
};

export default Testimonial;
